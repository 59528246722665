<template>
    <div
        :class="`yes-div
            ${lg?'':flexIndex? 'flex-lg-1':''}
            ${md?'':flexIndex? 'flex-md-1':''}
            ${xs?'':flexIndex? 'flex-xs-1':''}
            ${initClass}
            ${lg? ('yes-lg-' + lg):''} 
            ${md? ('yes-md-' + md):''}
            ${xs? ('yes-xs-' + xs):''}
            ${defealtClass}
            ${scrollShow?'':'scrollShow'}
        `"
        :ref="formCreateInject.rule.__fc__.ref"
        :style="`width:${width_show?width_show:''};
            max-width:${width_show?width_show:''};
            z-index:${zIndex? zIndex: 'inherit'};
            height:${height_show};
            min-width:${width_show? width_show: widthMin};
            min-height:${heightMin?heightMin: isDrag?'100px':''};
            flex-direction:${flexDirection};
            justify-content:${isDrag?'':flexDirection == 'row'? justifyValue: alignValue};
            align-items:${isDrag?'':flexDirection == 'row'? alignValue: justifyValue};
            display:${(isHidden && !isDrag)? 'none':'flex'};
            position:${position == 'adaptive' || position == '' ?'relative': isFix && !isDrag? 'fixed': 'absolute'};
            margin-left:${(position == 'adaptive' || position == '') && marginLeftValue? marginLeftValue: ''};
            margin-right:${(position == 'adaptive' || position == '')&&marginRightValue? marginRightValue: ''};
            margin-top:${(position == 'adaptive' || position == '')&&marginTopValue? marginTopValue: ''};
            margin-bottom:${(position == 'adaptive' || position == '')&&marginBottomValue? marginBottomValue: ''};
            padding-left:${paddingLeft? paddingLeft: ''};
            padding-right:${paddingRight? paddingRight: ''};
            padding-top:${paddingTop? paddingTop: ''};
            padding-bottom:${paddingBottom? paddingBottom: ''};
            top:${(position == 'adaptive' || position == '') ?'': position == 'top'? marginTopValue? marginTopValue: 0 : marginTopValue? marginTopValue: ''};
            bottom:${ (position == 'adaptive' || position == '') ? '':  position == 'bottom'? marginBottomValue? marginBottomValue: 0: marginBottomValue? marginBottomValue: '' };
            left:${ (position == 'adaptive' || position == '') ?'': position == 'left'? marginLeftValue? marginLeftValue: 0: marginLeftValue? marginLeftValue: ''};
            right:${(position == 'adaptive' || position == '') ?'': position == 'right'? marginRightValue? marginRightValue: 0: marginRightValue? marginRightValue: '' };
            overflow-x:${scrollX?'scroll':''};
            overflow-y:${scrollY?'scroll':''};
            background-image:${backImg? `url(${backImgUrl})`: ''};
            background-repeat:${backShowType};
            background-size:${coverType == 'cover'?'cover':'100% 100%'};
            flex-wrap:${wrap? 'wrap': ''};
            ${definedCss}
        `"
        @click="handleClick"
    >
        <slot />
    </div>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-div",
    props: {
        initClass: String,
        defealtClass: String,
        definedCss: String,
        formCreateInject: {
            type: Object,
            required: true,
        },
        position: {
            type: String,
            default: "",
        },
        zIndex: String,
        width: {
            type: String,
        },
        widthMin: {
            type: String,
        },
        widthDesigner: {
            type: String,
        },
        height: {
            type: String,
        },
        heightMin: {
            type: String,
        },
        heightDesigner: {
            type: String,
        },
        wrap: {
            type: Boolean,
        },
        lg: {
            type: String,
        },
        md: {
            type: String,
        },
        xs: {
            type: String,
        },
        marginLeftValue: {
            type: String,
        },
        marginRightValue: {
            type: String,
        },
        marginTopValue: {
            type: String,
        },
        marginBottomValue: {
            type: String,
        },
        paddingLeft: {
            type: String,
        },
        paddingRight: {
            type: String,
        },
        paddingTop: {
            type: String,
        },
        paddingBottom: {
            type: String,
        },
        isFix: {
            type: Boolean,
            default: false,
        },
        flexDirection: String,
        justifyValue: String,
        alignValue: String,
        scrollY: {
            type: Boolean,
            default: false,
        },
        scrollX: {
            type: Boolean,
            default: false,
        },
        scrollShow: {
            type: Boolean,
            default: false,
        },
        hiddenInit: {
            type: Boolean,
            default: true,
        },
        hiddenKey: {
            type: String,
            default: "",
        },
        backImg: Boolean,
        backImgUrl: String,
        backShowType: String,
        coverType: String,
        clickEvent: String,
        hidderScript: String,
        disabledScript: String,
    },
    data() {
        return {
            isHidden: false,
            isDrag: false,
            flexIndex: "flex-1",
            width_show: "",
            height_show: "",
        };
    },
    watch: {
        hiddenInit() {
            this.isHidden = this.hiddenInit;
        },
        justifyValue() {
            this.justifyValueChange();
        },
        alignValue() {
            this.alignValueChange();
        },
        width(val) {
            if (!this.widthDesigner) {
                this.width_show = val;
                this.widthChange();
            }
        },
        height(val) {
            if (!this.heightDesigner) {
                this.height_show = val;
                this.heightChange();
            }
        },
        widthDesigner(val) {
            this.width_show = val;
            this.widthChange();
        },
        heightDesigner(val) {
            this.height_show = val;
            this.heightChange();
        },
        flexDirection() {
            this.flexDirectionChange();
        },
    },
    created() {
        // 默认隐藏
        this.isHidden = this.hiddenInit;

        // 根据权限隐藏
        if (this.hiddenKey !== "" && this.hiddenKey !== undefined) {
            let permissions = this.$store.getters.permissions;
            this.isHidden = permissions && permissions.indexOf(this.hiddenKey) > -1 ? this.isHidden : true;
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$children[0] && this.$children[0].$options._componentTag === "dragTool") {
                this.isDrag = true;
                this.flexDirectionChange();
            }
            this.width_show = this.isDrag ? (this.widthDesigner ? this.widthDesigner : this.width) : this.width;
            this.height_show = this.isDrag ? (this.heightDesigner ? this.heightDesigner : this.height) : this.height;
            this.$nextTick(() => {
                this.widthChange();
                this.heightChange();
            });
        });
    },
    methods: {
        show() {
            this.isHidden = false;
        },
        hidden() {
            this.isHidden = true;
        },
        changeShow() {
            this.isHidden = !this.isHidden;
        },
        getHidValue() {
            return this.isHidden;
        },
        setWidth(val) {
            this.width_show = val;
            this.widthChange();
        },
        setHeight(val) {
            this.height_show = val;
            this.heightChange();
        },
        widthChange() {
            var isRow = this.$parent.$el.style.flexDirection == "row";
            if (isRow) {
                if (this.width_show != "" && this.width_show != undefined) {
                    this.flexIndex = "";
                } else {
                    this.flexIndex = "flex-1";
                }
            }
        },
        heightChange() {
            var isColumn = this.$parent.$el.style.flexDirection == "column" || this.$parent.$el.style.flexDirection == "";
            if (isColumn) {
                if (this.height_show != "" && this.height_show != undefined) {
                    this.flexIndex = "";
                } else {
                    this.flexIndex = "flex-1";
                }
            }
        },
        flexDirectionChange() {
            this.$children[0].$children[0].$el.style.flexDirection = this.flexDirection;
            this.justifyValueChange();
            this.alignValueChange();
        },
        justifyValueChange() {
            if (this.justifyValue != "none") {
                if (this.flexDirection == "row") {
                    this.$children[0].$children[0].$el.style.justifyContent = this.justifyValue;
                } else {
                    this.$children[0].$children[0].$el.style.alignItems = this.justifyValue;
                }
            } else {
                if (this.flexDirection == "row") {
                    this.$children[0].$children[0].$el.style.justifyContent = "";
                } else {
                    this.$children[0].$children[0].$el.style.alignItems = "";
                }
            }
            // if(this.justifyValue != 'none' && this.justifyValue != '') {
            //   this.$children[0].$children[0].$el.style.flexDirection = 'row'
            //   this.$children[0].$children[0].$el.style.justifyContent = this.justifyValue
            // }else {
            //   this.$children[0].$children[0].$el.style.flexDirection = 'column'
            //   this.$children[0].$children[0].$el.style.justifyContent = ''
            // }
        },
        alignValueChange() {
            if (this.alignValue != "none") {
                if (this.flexDirection == "row") {
                    this.$children[0].$children[0].$el.style.alignItems = this.alignValue;
                } else {
                    this.$children[0].$children[0].$el.style.justifyContent = this.alignValue;
                }
            } else {
                if (this.flexDirection == "row") {
                    this.$children[0].$children[0].$el.style.alignItems = "";
                } else {
                    this.$children[0].$children[0].$el.style.justifyContent = "";
                }
            }
            // if( this.justifyValue !=  'none' && this.alignValue !=  'none') {
            //   this.$message({
            //     message: '开启垂直布局先将水平布局设为默认',
            //     type: 'warning'
            //   });
            // }else{
            //   if(this.alignValue != 'none' && this.alignValue != '') {
            //     this.$children[0].$children[0].$el.style.flexDirection = 'column'
            //     this.$children[0].$children[0].$el.style.alignContent = this.alignValue
            //     this.justifyValue = 'none'
            //   }
            // }
        },
        handleScroll() {
            let scrollDiv = this.$refs[this.formCreateInject.rule.__fc__.ref];
            setTimeout(() => {
                if (scrollDiv.scrollTop !== scrollDiv.scrollHeight - scrollDiv.offsetHeight) {
                    scrollDiv.scrollTop = scrollDiv.scrollHeight - scrollDiv.offsetHeight;
                }
            }, 100);
        },
        async handleClick() {
            if (!this.isDrag) {
                const clickEvent = window.AsyncFunction("vm", "api", "dao", "utils", this.clickEvent);
                await clickEvent(this, this.formCreateInject.api, dao, utils);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yes-div {
    // background-color: rgb(136, 128, 128);
    .drag-box {
        height: 100% !important;
        display: inherit;
    }

    .drag-tool {
        display: inherit;
        flex: 1;
        overflow: auto;
        // height: calc(100%);
    }
}
</style>
<style lang="scss">
.scrollShow::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
}

.yes-div {
    .drag-tool.active {
        outline: 0px !important;
        border: 2px solid #2e73ff !important;
    }
}

.flex-1 {
    flex: 1;
}

.yes-div-drag {
    height: 100% !important;
    flex-direction: column;
    display: flex;
}
</style>
