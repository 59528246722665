<template>
  <el-button :disabled="btn_disabled" :type="typeStyle" :size="size" :plain="plain" :round="round" :circle="circle"
    :loading="fLoading" :icon="icon" :autofocus="false" @click="handleClick(clickEvent)">
    {{ fContent }}
  </el-button>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: "yes-button",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    content: {
      type: String,
    },
    size: {
      type: String,
    },
    typeStyle: {
      type: String,
    },
    plain: {
      type: Boolean,
    },
    round: {
      type: Boolean,
    },
    circle: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    dataSource: String,
    clickEvent: String,
    afterCreateScript: String,
  },
  data() {
    return {
      fvalue: "",
      fDataSource: this.dataSource,
      btn_disabled: false,
      fLoading: this.loading,
      fContent: this.content
    };
  },
  created() {
    this.btn_disabled = this.disabled;
  },
  async mounted() {
    this.afterCreate();
  },
  methods: {
    setCircle(circle) {
      this.circle = circle;
    },
    setContent(content) {
      this.fContent = content;
    },
    getContent() {
      return this.fContent;
    },
    setDisabled(value) {
      this.btn_disabled = value;
    },
    setLoading(value) {
      this.fLoading = value;
    },
    getValue() {
      return this.fvalue;
    },
    // 修改组件的值
    setValue(val) {
      this.fvalue = val;
    },
    loadSourceData() {
      const req = utils.getSourceData(this.fDataSource);
      if (req) {
        this.fvalue = req;
      }
    },
    isDarg() {
      if (this.$parent.$options._componentTag == "dragTool") {
        return true;
      }
    },
    async handleClick() {
      if (!this.isDarg()) {
        const clickEvent = window.AsyncFunction(
          "vm",
          "api",
          "dao",
          "fApi",
          "utils",
          this.clickEvent
        );
        await clickEvent(
          this,
          this.formCreateInject.api,
          dao,
          this.formCreateInject,
          utils
        );
      }
    },
    async afterCreate() {
      const afterCreateEvent = window.AsyncFunction(
        "vm",
        "api",
        "dao",
        "fApi",
        "utils",
        this.afterCreateScript
      );
      await afterCreateEvent(
        this,
        this.formCreateInject.api,
        dao,
        this.formCreateInject,
        utils
      );
    },
  },
};
</script>

<style></style>